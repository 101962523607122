<script setup lang="ts">
const lastY = ref(0)
const headerOffset = ref(0)
const contentTransparency = ref(1)
const headerElement = ref<HTMLElement | null>(null)

function onScroll() {
  const scrollTop = Math.max(window.scrollY, 0)
  if (headerElement.value) {
    if (scrollTop < lastY.value) {
      // Going up (show header)
      headerOffset.value += lastY.value - scrollTop
      headerOffset.value = Math.min(headerOffset.value, 0)
    } else {
      // Going down (hide header)
      headerOffset.value += (lastY.value - scrollTop) * 0.75
      headerOffset.value = Math.max(
        headerOffset.value,
        headerElement.value.scrollHeight * -1,
      )
    }

    contentTransparency.value =
      1 - headerOffset.value / (headerElement.value.scrollHeight * -1)
  }

  lastY.value = scrollTop
}

onMounted(() => {
  window.addEventListener("scroll", onScroll)
})

onUnmounted(() => {
  window.removeEventListener("scroll", onScroll)
})

const mobileNavOpen = ref(false)
</script>

<template>
  <div class="h-12">
    <MobileSidebar :open="mobileNavOpen" @close="mobileNavOpen = false" />
    <header
      ref="headerElement"
      class="z-40 w-full top-0 h-12 bg-white shadow-md"
      :style="{
        position: headerOffset === 0 ? 'fixed' : 'absolute',
        top: headerOffset === 0 ? 0 : lastY + 'px',
        marginTop: headerOffset + 'px',
      }">
      <div
        class="w-full h-full grid grid-cols-[1.5rem,1fr,1.5rem] place-items-center px-5"
        :style="{ opacity: contentTransparency }">
        <div>
          <button
            class="aspect-square w-8 bg-dusk-100 rounded flex justify-center items-center"
            @click="mobileNavOpen = !mobileNavOpen">
            <Icon class="text-[1.3rem]" name="solar:hamburger-menu-linear" />
          </button>
        </div>
        <NuxtLink to="/" class="font-heading text-part font-bold">
          Deimos
        </NuxtLink>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import { skipToken } from "@tanstack/vue-query"

import { projectsQueryKey } from "~/api/queryKeys"
import { getProject } from "~/api/requests/projects"
import { useProjectTabs } from "~/composables/useProjectTabs"
import { getDisplayName } from "~/utils/user"

const props = defineProps<{
  open: boolean
}>()

const { path, params } = useRoute()

const { $fromShortId } = useNuxtApp()
const projectIdRoute = params.projectId as string | undefined
const projectId = projectIdRoute ? $fromShortId(projectIdRoute) : undefined

const { data: project } = await useDeimosQuery({
  queryKey: [projectsQueryKey, projectId],
  queryFn: computed(() =>
    projectId ? () => getProject(projectId) : skipToken,
  ),
})

const projectTabs = useProjectTabs(project)
const filteredTabs = computed(() =>
  projectTabs.value.filter((tab) => !tab.hidden),
)

const emit = defineEmits<(event: "close") => void>()

watch(
  () => path,
  () => {
    if (props.open) emit("close")
  },
)

const auth = useAuthStore()

function removeStyles() {
  const html = document.body.parentNode as HTMLHtmlElement
  setTimeout(() => {
    document.body.classList.remove("overflow-hidden")
    html.style.scrollbarGutter = "initial"
  }, 200)
}

watch(
  () => props.open,
  (isOpen) => {
    const html = document.body.parentNode as HTMLHtmlElement
    if (isOpen) {
      document.body.classList.add("overflow-hidden")
      html.style.scrollbarGutter = "stable"
    } else {
      removeStyles()
    }
  },
)

onBeforeUnmount(removeStyles)

const iconMap = {
  "home": "mingcute:home-3-fill",
  "projects": "mingcute:drawer-2-fill",
  "details": "mage:lens-fill",
  "reports": "mingcute:clipboard-fill",
  "detail view": "ri:zoom-in-fill",
  "visualisation dashboards": "mage:chart-fill",
  "external users": "mingcute:group-fill",
  "value maps": "bxs:chart",
  "scenarios": "mdi:chart-gantt",
} as const

const displayUsername = computed(() => {
  if (!auth.isLoggedIn) return "Not logged in"
  return getDisplayName(auth.user)
})
</script>

<template>
  <div
    class="bg-black bg-opacity-50 z-50 fixed top-0 left-0 w-full h-full transition-opacity duration-300"
    :class="open ? 'opacity-100' : 'pointer-events-none opacity-0'"
    @click="$emit('close')" />
  <aside
    class="w-80 bg-white fixed top-0 left-0 z-50 transform transition-transform h-full overflow-y-auto pb-8"
    :class="open ? 'translate-x-0' : '-translate-x-full'">
    <div class="px-5">
      <div class="h-12 flex items-center gap-4">
        <button
          class="aspect-square w-8 bg-dusk-100 rounded flex justify-center items-center"
          @click="$emit('close')">
          <Icon class="text-[1.3rem]" name="solar:hamburger-menu-linear" />
        </button>
        <NuxtLink to="/" class="font-heading text-part font-bold">
          Deimos
        </NuxtLink>
      </div>

      <!-- Global links -->
      <div>
        <SideNavSection label="Deimos">
          <SideNavItem :icon="iconMap.home" to="/" exact> Home </SideNavItem>
          <SideNavItem :icon="iconMap.projects" to="/projects" exact>
            Projects
          </SideNavItem>
          <SideNavDropdown
            icon="mingcute:user-3-fill"
            :label="displayUsername"
            :open-override="false">
            <SideNavItem to="/profile" icon="mingcute:settings-2-fill">
              Profile and Settings
            </SideNavItem>
            <SideNavItem
              to="https://middlegatesoftware.slite.page/p/tnwmI6SNzeRTVb/Deimos-Handbook"
              icon="mingcute:question-fill">
              Help
            </SideNavItem>
            <SideNavItem to="/changelog" icon="mingcute:document-2-fill">
              Changelog
            </SideNavItem>
            <SideNavItem
              danger
              icon="mingcute:exit-door-fill"
              @click="auth.logout()">
              Logout
            </SideNavItem>
          </SideNavDropdown>
        </SideNavSection>
      </div>

      <!-- Project tabs -->
      <div v-if="projectTabs.length > 0">
        <SideNavSection label="Project links">
          <SideNavItem
            v-for="tab in filteredTabs"
            :key="tab.to"
            :to="tab.to"
            :icon="
              iconMap[tab.label.toLowerCase() as keyof typeof iconMap] ??
              'fluent-mdl2:unknown-solid'
            "
            exact>
            {{ tab.label }}
          </SideNavItem>
        </SideNavSection>
      </div>

      <!-- Scenario links and the likes. This is a Teleport -->
      <div id="sidebar"></div>
    </div>
  </aside>
</template>

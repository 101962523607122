<script lang="ts" setup>
import { useModalStore } from "~/stores/modals"

const modal = useModalStore()
</script>

<template>
  <div>
    <GlobalButtonsModal :open="modal.isOpen" />
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from "~/stores/modals"

const modal = useModalStore()

defineProps<{ open: boolean }>()
</script>

<template>
  <Modal :open="open" :z-base="10000" @close="modal.close">
    <ModalCard>
      <div>
        <Icon
          v-if="modal.icon"
          :name="modal.icon.name"
          :class="[modal.icon.class, 'mb-2']"
          size="32" />

        <ModalTitle sm left>{{ modal.title }}</ModalTitle>
      </div>
      <ModalParagraph v-if="modal.message">{{ modal.message }}</ModalParagraph>
      <Button
        v-for="button of modal.buttons"
        :key="button.text"
        class="mr-3"
        :variant="button.type"
        @click="button.onClick">
        {{ button.text }}
      </Button>
    </ModalCard>
  </Modal>
</template>
